import { default as React, useEffect, useRef, useState } from 'react'

import { ApolloProvider } from '@apollo/client'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license-pro'
import * as Sentry from '@sentry/react'
import { Cable } from 'actioncable'

import 'assets/css/bootstrap.min.css'
import { Routes, AppVersionModal } from 'components/Index'
import { FullStory } from 'components/common/FullStory'
import {
  CROSS_DOMAIN_STORAGE_URL,
  GRAPHQL_INITIAL_STATE,
  LOCAL_STORAGE_KEYS,
} from 'constants/static'
import AppContext from 'contexts/appContext'
import { GET_LOCAL_CACHED_DATA } from 'graphql/queries'
import createCustomMuiTheme from 'utils/createCustomMuiTheme'
import client from 'utils/createGraphqlClient'

import 'App.css'

require('dotenv').config()

client.writeQuery({
  query: GET_LOCAL_CACHED_DATA,
  data: GRAPHQL_INITIAL_STATE,
})

// MUI setup
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

LicenseInfo.setLicenseKey(
  '0357a396becb8152d6f45a4447645433Tz05MjY3MCxFPTE3NTAyNzI4ODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
)

// Or Create your Own theme:
const theme = createCustomMuiTheme()

if (!process.env.CI) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT || 'development',
    debug: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration({ maskAllText: false })],
  })
}

const App = () => {
  const [pageTitle, setPageTitle] = useState<
    string | { breadcrumbs: string[]; onBack: () => void } | null
  >('')
  const crossDomainIFrame = useRef<HTMLIFrameElement>({} as HTMLIFrameElement)
  const [crossDomainIFrameLoaded, setCrossDomainIFrameLoaded] = useState(false)
  const [actionCableApp, setActionCableApp] = useState({} as Cable)

  useEffect(() => {
    if (crossDomainIFrame.current!!) {
      crossDomainIFrame.current.addEventListener(
        'load',
        onCrossDomainIframeLoad,
      )
    }
    window.addEventListener('message', handleCrossDomainMessages, false)

    return () => {
      window.removeEventListener('message', handleCrossDomainMessages)
    }
  }, [])

  const onCrossDomainIframeLoad = () => {
    if (crossDomainIFrame.current!!) {
      crossDomainIFrame!.current!.contentWindow!.postMessage(
        {
          action: 'get',
          key: LOCAL_STORAGE_KEYS.TOKEN,
        },
        CROSS_DOMAIN_STORAGE_URL || '*',
      )
    }
  }

  const saveCrossDomainToken = (value: string) => {
    if (!crossDomainIFrameLoaded) {
      return
    }

    crossDomainIFrame!.current!.contentWindow!.postMessage(
      {
        action: 'save',
        key: LOCAL_STORAGE_KEYS.TOKEN,
        value,
      },
      CROSS_DOMAIN_STORAGE_URL || '*',
    )
  }

  const handleCrossDomainMessages = (event: MessageEvent<any>) => {
    const {
      data: { action, key, value },
      origin,
    } = event

    if (action === 'returnData' && origin === CROSS_DOMAIN_STORAGE_URL) {
      switch (key) {
        case LOCAL_STORAGE_KEYS.TOKEN: {
          const tokenFromLocalStorage = localStorage.getItem(
            LOCAL_STORAGE_KEYS.TOKEN,
          )
          if (!tokenFromLocalStorage && value) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, value)
            const data = client.readQuery({ query: GET_LOCAL_CACHED_DATA })
            client.writeQuery({
              query: GET_LOCAL_CACHED_DATA,
              data: { ...data, ...{ isLoggedIn: true } },
            })
          }
        }
      }
      setCrossDomainIFrameLoaded(true)
    }
  }

  return (
    <>
      <iframe
        id="cross-domain-iframe"
        style={{ display: 'none' }}
        ref={crossDomainIFrame}
        src={CROSS_DOMAIN_STORAGE_URL}
      />
      {(!CROSS_DOMAIN_STORAGE_URL || crossDomainIFrameLoaded) && (
        <ApolloProvider client={client}>
          <AppContext.Provider
            value={{
              pageTitle,
              setPageTitle,
              saveCrossDomainToken,
              actionCableApp,
              setActionCableApp,
            }}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <AppVersionModal />
                <Routes />
              </ThemeProvider>
            </StyledEngineProvider>
            <FullStory />
          </AppContext.Provider>
        </ApolloProvider>
      )}
    </>
  )
}

export default App
